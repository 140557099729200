import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "mx-context-menu-scroll",
  ref: "scroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextSubMenu = _resolveComponent("ContextSubMenu", true)!
  const _component_ContextMenuItem = _resolveComponent("ContextMenuItem")!
  const _component_VNodeRender = _resolveComponent("VNodeRender")!
  const _component_ContextMenuSeparator = _resolveComponent("ContextMenuSeparator")!
  const _component_ContextMenuIconRight = _resolveComponent("ContextMenuIconRight")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('mx-context-menu ' + (_ctx.options.customClass ? _ctx.options.customClass : '') + ' ' + _ctx.globalTheme),
    style: _normalizeStyle({
      maxWidth: (_ctx.maxWidth ? _ctx.maxWidth : `${_ctx.constOptions.defaultMaxWidth}px`),
      minWidth: _ctx.minWidth ? _ctx.minWidth : `${_ctx.constOptions.defaultMinWidth}px`,
      maxHeight: _ctx.overflow && _ctx.maxHeight > 0 ? `${_ctx.maxHeight}px` : undefined,
      zIndex: _ctx.zIndex,
      left: `${_ctx.position.x}px`,
      top: `${_ctx.position.y}px`,
    }),
    "data-type": "ContextSubMenu",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubMenuBodyClick && _ctx.onSubMenuBodyClick(...args)))
  }, [
    _createElementVNode("div", {
      class: "mx-context-menu-items",
      ref: "menu",
      style: _normalizeStyle({
        top: `${_ctx.scrollValue}px`,
      })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            _createVNode(_component_ContextMenuItem, {
              clickHandler: item.onClick,
              disabled: item.disabled,
              hidden: item.hidden,
              icon: item.icon,
              iconFontClass: item.iconFontClass,
              svgIcon: item.svgIcon,
              svgProps: item.svgProps,
              label: item.label,
              customRender: (item.customRender as Function),
              customClass: item.customClass,
              checked: item.checked,
              shortcut: item.shortcut,
              clickClose: item.clickClose,
              clickableWhenHasChildren: item.clickableWhenHasChildren,
              preserveIconWidth: item.preserveIconWidth !== undefined ? item.preserveIconWidth : _ctx.options.preserveIconWidth,
              showRightArrow: item.children && item.children.length > 0,
              hasChildren: item.children && item.children.length > 0
            }, _createSlots({ _: 2 }, [
              (item.children && item.children.length > 0)
                ? {
                    name: "submenu",
                    fn: _withCtx(() => [
                      _createVNode(_component_ContextSubMenu, {
                        items: item.children,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                        adjustPosition: item.adjustSubMenuPosition !== undefined ? item.adjustSubMenuPosition : _ctx.options.adjustPosition
                      }, null, 8, ["items", "maxWidth", "minWidth", "adjustPosition"])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["clickHandler", "disabled", "hidden", "icon", "iconFontClass", "svgIcon", "svgProps", "label", "customRender", "customClass", "checked", "shortcut", "clickClose", "clickableWhenHasChildren", "preserveIconWidth", "showRightArrow", "hasChildren"]),
            (item.hidden !== true && item.divided && _ctx.globalHasSlot('separatorRender'))
              ? (_openBlock(), _createBlock(_component_VNodeRender, {
                  key: 0,
                  vnode: () => _ctx.globalRenderSlot('separatorRender', {})
                }, null, 8, ["vnode"]))
              : (item.hidden !== true && item.divided)
                ? (_openBlock(), _createBlock(_component_ContextMenuSeparator, { key: 1 }))
                : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ], 4),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", {
        class: "mx-context-menu-updown mx-context-no-clickable up",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onScroll(false)))
      }, [
        _createVNode(_component_ContextMenuIconRight)
      ], 512), [
        [_vShow, _ctx.overflow && _ctx.scrollValue < 0]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "mx-context-menu-updown mx-context-no-clickable down",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onScroll(true)))
      }, [
        _createVNode(_component_ContextMenuIconRight)
      ], 512), [
        [_vShow, _ctx.overflow && _ctx.scrollValue > -_ctx.scrollHeight]
      ])
    ], 512)
  ], 6))
}